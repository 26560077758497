<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="l_r_space bgc_f4fbff contact_us">
        <a-row type="flex" style="align-items: center">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :xxl="12">
            <img
              style="max-width: 360px"
              src="@/assets/img/png/contactUs.png"
              alt="联系我们"
              width="90%"
            />
          </a-col>
          <a-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            :xxl="12"
            class="text_left"
            style="color: #666"
          >
            <div
              class="font-size_20"
              style="padding-bottom: 0.3rem; font-weight: bold"
            >
              <p style="color: #333">Tel:400-118-0106</p>
              <p>EMAIL:hr@jooyum.com</p>
            </div>
            <div class="font-size_16">
              <p>上海（总部）：上海市长寿路652号景源时尚产业园10号楼202室</p>
              <p>石家庄（分公司）：河北省石家庄市新华区友谊北大街345号中粮河北广场B座1802</p>
            </div>

            <div class="qr_code_box">
              <div style="display: inline-block; padding-right: 0.66rem">
                <div class="qr_code_more"></div>
                <div style="font-size: 13px">扫描二维码了解更多</div>
              </div>
              <div style="display: inline-block">
                <div class="qr_code_focus"></div>
                <div style="font-size: 13px">扫描二维码关注公众号</div>
              </div>
            </div>
            <div class="font-size_16">
              <p>正也科技面向广大企业与个人招募商务合作伙伴和代理商，</p>
              <p>如有合作意向，欢迎发邮件到：hr@jooyum.com,</p>
              <p>
                收到您的邮件后我们的商务经理将在3个工作日内与您联系，洽谈商务合作事宜。
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
export default {
  name: "contactUs",
  beforeCreate() {},
  components: { Footer, HeaderMenu },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.contact_us {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.qr_code_more {
  background: url("~@/assets/img/png/qr_code_more.png");
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
}
.qr_code_focus {
  background: url("~@/assets/img/png/qr_code_official_accounts.png");
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
}
.qr_code_box {
  padding: 0.15rem 0 0.45rem 0;
}
@media all and (max-width: 768px) {
  .qr_code_more,
  .qr_code_focus {
    width: 90px;
    height: 90px;
  }
}
@media all and (max-width: 576px) {
  .qr_code_more,
  .qr_code_focus {
    width: 80px;
    height: 80px;
  }
}
</style>
